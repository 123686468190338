import React, { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    redirect();
   }, []);
 
   function redirect() {
     
     let universalUrl = window.location.href;
 
     let url = new URL(universalUrl);
     const GameType = new URLSearchParams(url.search);
     let game = GameType.get("game")?.toString();
 
  
     var redirectUrl = "";
     if (game?.includes("50")) 
     {
       redirectUrl = universalUrl.replace("https://playtech.catmonocle.com/", "https://fiftyfifty.catmonocle.com/");
       console.log("50/50 true");
     } else if (game?.includes("AB-Single")) 
     {
       redirectUrl = universalUrl.replace("https://playtech.catmonocle.com/", "https://5050singleplayer.catmonocle.com/");
       console.log("AB-Single true");
     }
     else
     {
       redirectUrl = universalUrl.replace("https://playtech.catmonocle.com/", "https://playwithfriends.catmonocle.com/");
       console.log("Playwithfriends true");
     }
 
     window.location.replace(redirectUrl);
   }
   
  return (
    <div>
      <img src={"/assets/logo.png"} width={80} alt="logo" />
    </div>
  );
}
